/* eslint-disable indent */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AppBar, Toolbar, IconButton, Grid, Box, Popover, Slide } from '@mui/material'
import {
  KeyboardArrowDown,
  Menu,
  Close,
  Search as SearchIcon,
  CloseOutlined
} from '@mui/icons-material'
import { ChatAIWhite } from '../icons'
import MTLogo from '../logos/mt-for-business.svg'
import Search from './search'
import { useSearchQuery } from '../../pages/search-results/hooks'
import { useNavigate } from 'react-router'
import ExploreMenu from '../explore-menu'
import { ActionButton } from '../action-buttons'
import { useTenant } from '@emerald-works-nova/auth'
import { Link as RouterLink } from 'react-router-dom'
import { ImageUtil } from '@emerald-works-nova/components'
import ActionBar from './action-bar'
import CustomHidden from './hidden/'
import useFormFactor from './hooks/useFormFactor'
import MenuButton from './menu-button'
import { AppbarActionContext } from './context'
import { useStyles } from './style'
import { useAlert, useGroup, useProducts } from '../../hooks'
import { ALL_ADMIN, ALL_CONSUMER, ALL_ENTERPRISE, CONSUMER } from '../../services/cognito/userGroups'
import useLogout from '../../hooks/useLogout'
import { routes } from '../../routes'
import ConfirmDialog from '../confirm-dialog'
import { useSession } from '@emerald-works/react-auth'
import consts from '../../consts'
import { newsletterSlice } from '../../reducers'
import { useDispatch, useSelector } from 'react-redux'
import { SubscribeToNewsletterForm } from '../newsletter-form'
import { useEvent } from '@emerald-works/react-event-bus-client'
import MindsetAgent from '../mindset-agent'

const AppToolbar = ({ title }) => {
  const { formFactor, breakpoints } = useFormFactor()
  const newsletterPopoverOpen = useSelector(newsletterSlice.selectors.selectIsNewsletterPopoverOpen)
  const dispatch = useDispatch()
  const classes = useStyles({ bps: breakpoints })
  const [showExplore, setShowExplore] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false)
  const [showSuggestions, setShowSuggestions] = useState()
  const [showMindset, setShowMindset] = useState(false)
  const toolbarRef = useRef()
  const tenant = useTenant()
  const { hasContentHubAccess } = useProducts()
  const searchContainerRef = useRef()
  const searchBtnRef = useRef()
  const { isAuthenticated } = useSession()
  const [trackNewsletterClosePopover] = useEvent([{ eventName: 'newsletterClosePopover' }])
  const navigate = useNavigate()

  const { isInGroup } = useGroup()
  const isClubBrowserUser = tenant.pk === consts.DEFAULT_CLUB_PK && !isAuthenticated
  const hasMindsetAiFeature = isInGroup([...ALL_ENTERPRISE, ...ALL_ADMIN, CONSUMER]) && tenant.features?.mindsetAi

  const hasMindsetAiAccess = hasContentHubAccess && hasMindsetAiFeature && isAuthenticated
  const [askMCoachView] = useEvent([{ eventName: 'askMCoachView' }])

  const handleExploreMenuClose = () => setShowExplore(false)

  const { addFilter } = useSearchQuery()

  const { enqueueMsg } = useAlert()

  const { logout } = useLogout()

  const handleSearch = (term, from, position) =>
    navigate(addFilter({ search: term }, { routeKey: 'serp', clear: true }), {
      state: { from, position }
    })

  useEffect(() => {
    if (showSuggestions === false && formFactor === 'mobile') {
      searchBtnRef.current?.focus()
    }
  }, [showSuggestions, formFactor])

  const ImageLink = (props) => {
    if (isClubBrowserUser) {
      return (
        <a className={classes.logoWrapper} href={window.location.origin + '/'}>
          {props.children}
        </a>
      )
    }

    return (
      <RouterLink to={routes.home.path} className={classes.logoWrapper}>
        {props.children}
      </RouterLink>
    )
  }

  const renderLogo = () => (
    <ImageLink>
      <img
        src={
          tenant.logo?.s3Url
            ? ImageUtil.ImgixGenerateImageUrl(tenant.logo.s3Url, {
                h: 40,
                ...tenant.logo?.imgixCropParams
              })
            : MTLogo
        }
        height={40}
        width={200}
        alt={`${tenant.logo?.s3Url ? tenant.name : 'MindTools'} Home`}
        className={classes.logo}
        data-test='appbar-mt-logo'
      />
    </ImageLink>
  )

  const handleLogout = async () => {
    try {
      await logout(routes.login.path)
    } catch (error) {
      enqueueMsg('Unable to logout', 'error')
    }
  }

  const handleCloseNewsletterPopover = useCallback((evt, reason) => {
    dispatch(newsletterSlice.actions.closeNewsletterPopover())
    if (reason) {
      trackNewsletterClosePopover.trigger({
        from: reason
      })
    }
  }, [dispatch, trackNewsletterClosePopover])

  const handleShowMindset = useCallback((evt, source) => {
    setShowMindset(prevOpen => !prevOpen)
    if (!showMindset) {
      askMCoachView.trigger({ from: source })
    }
  }, [askMCoachView, showMindset])

  const actionContext = useMemo(() => {
    const getId = ({ props: { id } }) => id.replace(/-action$/, '')
    const handleActionClick = (evt, action) => {
      if (getId(action) === 'explore') {
        handleCloseNewsletterPopover()
        setShowExplore(true)
      } else if (getId(action) === 'logout') {
        handleCloseNewsletterPopover()
        setShowLogoutConfirm(true)
      } else if (getId(action) === 'mindsetai') {
        handleShowMindset(evt, 'navigation')
      }
    }

    const handleMenuShow = open => {
      setShowMenu(open)
    }

    return {
      onClick: handleActionClick,
      onMenuShow: handleMenuShow,
      getId
    }
  }, [handleCloseNewsletterPopover, handleShowMindset])

  const handleNewsletterSuccess = () => {
    setTimeout(() => {
      handleCloseNewsletterPopover()
    }, 4000)
  }

  return (
    <AppbarActionContext.Provider value={actionContext}>
      <Popover
        id='newsletter-popover'
        open={newsletterPopoverOpen}
        onClose={handleCloseNewsletterPopover}
        closeAfterTransition
        anchorReference='anchorPosition'
        classes={{ paper: classes.newsletterPopoverPaper, root: classes.newsletterPopoverRoot }}
        anchorPosition={{ top: 64, left: 0 }}
        TransitionComponent={Slide}
        style={{ zIndex: 1000 }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Box display='flex' flexDirection='row' gap='20px'>
          <SubscribeToNewsletterForm
            afterSuccess={handleNewsletterSuccess}
          />
          <IconButton size='small' aria-label='close-newsletter-popover' className={classes.closeButton} onClick={(evt) => handleCloseNewsletterPopover(evt, 'closeButton')}>
            <CloseOutlined />
          </IconButton>
        </Box>
      </Popover>
      <AppBar position='static' className={classes.appBar} ref={toolbarRef}>
        <Toolbar className={classes.toolbar} disableGutters>
          <CustomHidden tabletUp>
            <MenuButton
              id='appbar-menu'
              disableActiveState
              button={
                <IconButton
                  data-test={`appbar-${showExplore ? 'close' : 'menu'}-button`}
                  size='large'
                >
                  {showExplore || showMenu ? <Close /> : <Menu />}
                </IconButton>
              }
            />
          </CustomHidden>
          {renderLogo()}
          {/* Explore Menu Button on Desktop */}
          {hasContentHubAccess &&
            <CustomHidden mobileDown>
              <Grid>
                <ActionButton
                  variant={
                    isInGroup(ALL_CONSUMER) ? 'publicTenantSecondary' : 'primary'
                  }
                  endIcon={<KeyboardArrowDown />}
                  label='Explore'
                  data-test='appbar-expore-button'
                  onClick={() => {
                    setShowExplore(show => !show)
                    handleCloseNewsletterPopover()
                  }}
                />
              </Grid>
            </CustomHidden>}
          {/* Search Bar */}
          {hasContentHubAccess &&
            <Grid className={classes.searchContainer}>
              <Search
                onSearch={handleSearch}
                data-test='appbar-search'
                searchContainerRef={searchContainerRef}
                showSuggestions={showSuggestions}
                onShowSuggestions={setShowSuggestions}
              />
              {formFactor === 'mobile' && !showMenu && (
                <Box className={classes.searchButton}>
                  <IconButton
                    title='show search'
                    ref={searchBtnRef}
                    onClick={() => setShowSuggestions(true)}
                    size='large'
                  >
                    <SearchIcon />
                  </IconButton>
                </Box>
            )}
            </Grid>}
          <CustomHidden mobileDown>
            <ActionBar />
          </CustomHidden>
        </Toolbar>
      </AppBar>
      {/* Explore Menu */}
      {hasContentHubAccess && <ExploreMenu open={showExplore} onClose={handleExploreMenuClose} />}
      <ConfirmDialog
        name='confirm-logout'
        title='Are you sure you want to logout?'
        open={showLogoutConfirm}
        onClose={() => setShowLogoutConfirm(false)}
        onConfirm={handleLogout}
      />
      {hasMindsetAiAccess && (
        <>
          <MindsetAgent open={showMindset} onClose={handleShowMindset} />
          <Box className={classes.mindsetBubble} onClick={(evt) => handleShowMindset(evt, 'bubble')}>
            <ChatAIWhite />
          </Box>
        </>
      )}
    </AppbarActionContext.Provider>
  )
}

export default AppToolbar
